export default {
  title: "Rates",
  name: "Name",
  active_from: "Active from",
  created_on: "Created on",
  label_name: "Name:",
  label_active_from: "Active from:",
  label_created_on: "Created on:",
  title_no_rates: "No rates added yet",
  subtitle_no_rates: "As soon as the rates are added, they will be shown here",
};
