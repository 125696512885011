export default {
  button: {
    cancel: "Cancel",
    save: "Save",
    next: "Next",
    view_more: "View more",
    view_less: "View less",
    ok: "ok",
    back: "Back",
    close: "Close",
    accept: "Accept",
  },
  badge: {
    saved: "Saved",
  },
  components: {
    comments: {
      add: "Add a comment",
      save: "Save",
      title: "COMMENTS",
      view_more: "View More",
      view_less: "View Less",
      edit_comment_title: "Write a comment",
      reply: "Reply",
    },
    statusHistory: {
      title: "STATUS",
      add: "Add new status",
      title_incidence: "Specify incidence",
      label_reason: "Select a reason from the list",
      label_priority: "Choose a priority for the incidence",
      label_description: "Describe the incidence briefly",
    },
    tagPicker: {
      suggestions: "Suggestions",
      selected_current_tags: "Selected current tags",
      view_less: "View Less",
      view_more: "View More",
      create_tag: "Create tag",
    },
    HeaderMenu: {
      orders: "Orders",
      operations: "Operations",
      teams: "Teams",
      incidences: "Incidences",
      routes: "Last Mile",
      routes_new: "Last Mile (BETA)",
      routes_old: "Last Mile",
      suppliers: "Suppliers",
      organization: "Organization",
      organization_team: "GOI’s Drivers & installation technicians",
      organization_supplier: "All collaborators involved for linehauls",
      "organization_add-team": "Add Team",
      "organization_add-supplier": "Add supplier",
      linehaul: "Linehaul",
      dedicatedServices: "Dedicated",
      logout: "Logout",
      "link_action_report-problem": "Reportar problema con la plataforma",
      "link_action_change-status": "Solicitar cambio de estado en pedido",
      "link_action_delete-comment": "Solicitar eliminación de comentario",
      "link_action_register-operation":
        "Petición de alta de operativa / cliente",
      "total-count": "{pageItemFrom} - {pageItemTo} of {pageTotalItems}",
    },
    VComments: {
      field_message: "Add a comment",
      field_message_placeholder: "Write your comment",
      "not-content": "There are not comments yet",
      button_post: "Publish",
    },
    Sidebar: {
      "order-details": "Order Details",
      "customer-details": "Customer Details",
      "delivery-details": "Delivery Details",
      "content-details": "Content Details",
      reference: "Reference",
      "order-id": "Client Order Id",
      status: "Status",
      name: "Name",
      "last-name": "Last Name",
      email: "E-mail",
      address: "Address",
      "additional-address": "Additional Address",
      "zip-code": "Zip Code",
      city: "City",
      "delivery-from": "Delivery Time From",
      "delivery-to": "Delivery Time To",
      "link-order-detail": "Open full order details",
      operation: "Operation",
    },
    delete_service: {
      error: "Error",
      delete_text: "Delete",
      confirmation: "Are you sure?",
      yes: "Yes",
      no: "No",
      deleting: "Deleting...",
    },
    new_version: {
      title: "We’ve got a new version ready!",
      description: "Update now to get the last Brain Version",
      btn: "Update now",
    },
    BulkActions: {
      summary: {
        "text-1": "Your changes are processing now...",
        "text-2":
          "Your changes has been submitting and are being processed now.",
        "text-3":
          "This could take a while and we will email you back to notify you they were succeded as soon as the processing ends.",
        "text-4": "Meanwhile you can operate on BRAIN as usually.",
        close: "Close",
      },
      confirm: {
        title: "You will change",
        orders: "Orders",
        question: "That's it that you really want ?",
        accept: "Accept",
        cancel: "Cancel",
      },
    },
    filterMachine: {
      default_placeholder: "You don’t have any active filter",
      select_placeholder: "Select a filter",
      pick_placeholder: "Setup",
      selected_placeholder: "You have {count} active filter",
      datepicker_placeholder: "Select a date",
      add: "Add filter",
      "history-panel": {
        filters_applied: "Filters applied",
        clear_all: "Clear All",
        download_all: "Download All",
      },
      footer: {
        total_results: "Total results",
        button_apply_add_more: "Apply & Add More",
        button_apply: "Apply filters",
      },
      "selection-panel": {
        step_one: "Step 1/2",
        step_two: "Step 2/2",
        choose_condition: "Choose Condition",
        choose_range: "Choose Range",
        choose_value: "Choose Value",
      },
      headers: {
        filter_selection: "Apply filters for",
        steps_process: "Set up filter for",
      },
      interval: {
        start_date: "Start Date",
        finish_date: "Finish Date",
      },
      single: {
        search: "Search",
      },
    },
    attachmentFiles: {
      add: "Upload file",
      validity: "Created at",
      choose: "Choose a file from your computer",
      "not-content": "There are no attachments yet",
    },
    shortcutMaker: {
      label_creation: "Name this Shortcut",
      placeholder_creation: "type name of the shortcut",
      btn_creation: "Create shortcut",
      btn_cancel: "Cancel",
      btn_close: "Close",
      label_created: "Shortcut created!",
      description_created: "The shortcut has been succesfully created!",
    },
    sideAppMenu: {
      label_delete_shortcut: "Delete shortcut",
      description_delete_shortcut:
        "You are about to delete this shortcut, and this action can’t\n be undone. Are you sure you want to proceed?",
      btn_cancel: "Cancel",
      btn_delete: "Delete",
      no_results: "No results found",
      no_results_body: "We can’t find anything related to that search.",
      no_results_body_please: "Please try again with a more specific term.",
      section_orders: "Orders",
      section_incidences: "Incidences",
      section_linehauls: "Linehauls",
      "section_dedicated-services": "Dedicated Services",
    },
    columnsSwitcher: {
      select_columns: "Select specific data to view",
      select_columns_combined: "Select combined data to view",
      download_columns: "Select the columns you would like to download",
    },
    cardDepartments: {
      title: "Involved Departments",
      btn_edit: "Edit",
      select_from_list: "Select from the list",
      no_departments: "No departments assigned",
    },
    cardLocation: {
      title: "Base Location",
      subtitle: "Edit Base Location",
      btn_edit: "Edit",
      address: "Address",
      additional: "Additional Address",
      zip: "Zip Code",
      city: "City",
      province: "Province",
      country: "Country",
      placeholder_address: "",
      placeholder_additional_address: "Insert additional info",
      placeholder_city: "Madrid",
      placeholder_zip: "28012",
      placeholder_province: "",
      placeholder_country: "",
      no_base_address_title: "No Base Location Address",
      no_base_address_subtitle: "There is no map to show yet",
    },
    cardDocumentsManagement: {
      title: "Document Management",
      add_document: "Add document",
      upload_file: "Upload a file",
      drag_and_drop: "or drag and drop",
      formats: "PDF, PNG, JPG, GIF up to 10MB",
      no_document_added: "No document added yet",
      expiration_date: "Expiration Date",
      documents: "Documents",
      expired: "Expired",
      nearing_expiration: "Nearing expiration",
      history: "History",
      required_docs: "Required Docs",
      additional_docs: "Additional Docs",
      upload: "Upload",
      uploaded_at: "Uploaded at:",
      uploaded_by: "Uploaded by:",
      expiration: "Expiration:",
      upload_pending: "Upload pending",
      filed_docs: "Filed docs",
      delete_document: "Delete document",
      update_document: "Update document",
      preview_document: "Preview document",
      download_document: "Download document",
      filed_on: "Filed on:",
      title_placeholder_empty: "No Documents Filed yet",
      subtitle_placeholder_empty: "There are currently no documents filed away",
    },
    search: {
      placeholder_search: "Search",
      "total-count": "Min search length is 4 chars",
    },
  },
};
