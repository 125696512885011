export default {
  list: {
    title: "Teams",
    button_new: "Create New Team",
    button_add_team: "Add Team",
    "label_card-title": "Team",
    "label_card-name": "Name",
    "label_card-phone": "Phone",
    "button_download-selection": "Download Selection",
    "button_download-all": "Download Everything",
  },
  detail: {
    title: "Team",
    "success-updated": "Your changes have been applied",
    "error-updated_server-error": "Your changes haven’t been applied",
    team_details: "Team Details",
    base_location: "Base Location",
    Vehicle: "Vehicle",
    "saved-changes": "Saved!",
    "saving-changes": "Saving Changes...",
    "unsaved-changes": "Changes must be yet saved!",
    "fields-on-error": "Some fields are incorrect!",
  },
  creation: {
    title: "New Team",
    "success-created": "Team created successful",
    "error-created": "Something fail while creating the team",
    "error-created_server-error": "Something fail while creating the team",
    "creation-save": "Create Team",
    "btn-cancel": "Cancel",
  },
  form: {
    "field_provider_error-required": "Required field",
    field_country: "Country",
    field_country_placeholder: "Select Country",
    "field_country_error-required": "Required field",
    "field_country_error-must-contains": "Value not found",
    field_provider_placeholder: "Select Provider",
    field_provider: "Provider",
    information_title: "Team Details",
    base_location: "Base Location",
    vehicle: "Vehicle",
    field_name: "Name",
    field_weight: "Payload (kg)",
    field_volume: "Cargo Volume (m3)",
    "field_name_error-required": "Required field",
    field_phone: "Team Contact Number",
    field_phone_placeholder: "+34 678 894 123",
    field_weight_placeholder: "Insert Payload",
    field_volume_placeholder: "Insert Volume",
    field_plate: "Plate",
    "field_plate-placeholder": "2020 PPH",
    "field_phone_error-length": "Length field equal to 15",
    "field_phone_error-format": "Not format correct field",
    "field_generic_error-negative": "Sorry, negative values are not admitted",
    "field_generic_error-max": "Sorry, unusual high values are not admitted",
    "field_generic_min-value": "Sorry, minimum value is required",
    button_edit: "Edit",
    "button_finish-edit": "Finish Editing",
    button_create: "Create",
    button_save: "Save changes",
    button_saved: "Saved",
    "button_not-saved": "Not saved",
    "creating-team": "Creating team",
    for_failed: "for failed",
    "field_aditional-address": "Additional Address",
    "field_aditional-address_placeholder": "4th floor",
    field_address: "Address",
    field_address_placeholder: "Insert Customer Address, nº...",
    "field_address_error-required": "Required field",
    field_zip: "ZIP Code",
    field_zip_placeholder: "28012",
    "field_zip_error-required": "Required field",
    "field_zip_error-numeric": "Zip is not valid",
    "field_zip_error-length": "Length field equal to 5",
    field_city: "City",
    field_city_placeholder: "Madrid",
    "field_city_error-required": "Required field",
    field_password: "Peek Access",
  },
};
