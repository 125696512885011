import commonComponentsTranslations from "./en/common";
import authTranslations from "./en/auth";
import incidencesTranslations from "./en/incidences";
import alerts from "./en/alerts";
import {
  common as commonOrders,
  card as cardOrder,
  exports as exportsMenuOrder,
} from "./en/orders";
import { common as commonClients, card as cardClient } from "./en/clients";
import {
  common as commonOperations,
  card as cardOperation,
} from "./en/operations";

import {
  common as commonLinehauls,
  card as cardLinehaul,
} from "./en/linehauls";
import {
  common as commonDedicatedServices,
  card as cardDedicatedServices,
} from "./en/dedicatedServices";

import {
  common as commonProviders,
  card as cardProvider,
} from "./en/providers";

import { common as commonTeams, card as cardTeam } from "./en/teams";

export default {
  app: {
    login: "Login",
    recovery: "Recovery",
    "not-found": "Page Not found",
    orders: "Orders",
    "new-order": "New Order",
    incidences: "Incidences",
    "new-incidence": "New Incidence",
    operations: "Operations",
    teams: "Teams",
    "new-team": "New Team",
    providers: "Providers",
    "new-provider": "New Provider",
    routes: "Tracker",
    linehauls: "Linehauls",
    "new-linehaul": "New Linehaul",
    dedicated: "Dedicated",
    "new-dedicated": "New Dedicated",
    "dedicated-calendar": "calendar | Dedicated",
    empty: "",
    "not-found_title": "Error 404",
    "not-found_upper-title": "Ups! This page doesn’t exist.",
    "not-found_subtitle":
      "Maybe the url is wrong, maybe there is technical error. If you didn’t mistyped anything, please try again and if the error persist you can contact us at",
    "not-found_email": "support{'@'}letsgoi.com",
    "not-results_title": "Ouch! There are no results for this search.",
    "not-results_subtitle": "Please, check your spell and try again.",
    "form-errors":
      "A field is incorrect! | {totalerrors} fields are incorrect!",
    loading: "Loading...",
    "card-placeholder": "No {type} added yet",
    copy_clipboard: "Copy to Clipboard",
    deals: "Deals",
    "new-deal": "New Deal",
  },
  auth: {
    login: {
      field_email_error: "Check your email, there seems to be an error",
      "field_email_error-required": "Email is required.",
      "field_password_error-required": "Password is required.",
      field_new_password: "Repeat New Password",
      "field_new_password_error-required": "Required field.",
      "invalid-token": "Invalid token register with param access_token",
      ...authTranslations.login,
    },
    recovery: {
      title: "Create your new password",
      subtitle:
        "Please, type below the email associated to your account. If we have it already, we will instantly email you back a link to let you choose a new password for your account.",
      button_text: "Send me the email to create a new password",
      back: "Back to Log in",
      field_email: "Email",
      field_email_placeholder: "Ej: dolores{'@'}letsgoi.com",
      field_email_error: "Check your email, there seems to be an error.",
      "field_email_error-required": "Email is required.",
      fallback_message:
        "We are verifying we have your email. Please, check on your inbox if you received an email to let you create a new password.",
    },
    setPassword: {
      title: "Choose your new password",
      "field_new-password": "New Password",
      "field_repeat-password": "Repeat Password",
      "new-password_placeholder": "********",
      "repeat-password_placeholder": "********",
      button_text: "Create and Log me in",
      "new-password_error-required": "New Password is required.",
      "new-password_error-min-value":
        "The Password must be 8 characters minimum.",
      "repeat-password_error-required": "Repeat Password is required.",
      "same-password":
        "Sorry, password doesn’t match on both fields. Review carefully and try again.",
      fallback_message:
        "Remember the password must be 8 characters minimum. As a recommendation only, insert capitals and numbers to get a stronger password.",
    },
    errors: {
      "error_message_server-error":
        "Oops! Autentication failure. Please try again",
      "error_message_network-error":
        "The connection is not responding now. Try again in few minutes and if persists, please contact support",
      message_default:
        "There was a problem. Please, try again and if the problem persists contact us at tech{'@'}letsgoi.com",
      review: "Please review these warnings:",
    },
  },

  suppliers: {
    list: {
      title: "Suppliers",
      "label_card-provides": "Provides",
      button_new: "New Supplier",
      "total-count": "Showing {totalcount} Results",
    },
    creation: {
      profile: "Suppliers Profile",
      "creation-save": "Add Supplier",
      "btn-cancel": "Cancel",
    },
    detail: {
      save: "Save Changes",
    },
    form: {
      field_name: "Name",
      field_name_placeholder: "Insert Name",
      "field_name_error-required": "Required field",
      field_type_title: "What kind of supplier is?",
      field_type_subtitle: "Check both if necessary",
      "field_type_error-required": "Required field",
      "creating-supplier": "Creating  supplier",
      field_country: "Country",
      field_country_placeholder: "Select Country",
      "field_country_error-required": "Required field",
      "field_country_error-must-contains": "Value not found",
      base_location: "Base Location",
      "field_aditional-address": "Additional Address",
      "field_aditional-address_placeholder": "4th floor",
      field_address: "Address",
      field_address_placeholder: "Insert Customer Address, nº...",
      "field_address_error-required": "Required field",
      field_zip: "ZIP Code",
      field_zip_placeholder: "28012",
      "field_zip_error-required": "Required field",
      "field_zip_error-numeric": "Zip is not valid",
      "field_zip_error-length": "Length field equal to 5",
      field_city: "City",
      field_city_placeholder: "Madrid",
      "field_city_error-required": "Required field",
    },
  },
  linehaul: {
    list: {
      title: "Linehauls",
      button_new: "New Linehaul",
      table_headers: {
        id: "ID",
        reference: "Reference",
        orders: "Orders",
        arrived_at: "End Date",
        departed_at: "Start Date",
        origin_platform: "Origin Platform",
        destination_platform: "Destination Platform",
        provider_name: "Transport",
        created_by: "Created By",
        created_at: "Project created at",
        cost: "Cost",
        pallets: "Palets",
        status: "Status",
        observations: "Observations",
        origin_address: "Origin Deliv. Address",
        origin_additional_address: "Origin Deliv. Additional Add",
        origin_city: "Origin City",
        origin_zip: "Origin Zip Code",
        origin_province: "Origin Province",
        origin_country: "Origin Country",
        destination_address: "Destination Deliv. Address",
        destination_additional_address: "Destination Deliv. Additional Add",
        destination_city: "Destination City",
        destination_zip: "Destination Zip Code",
        destination_province: "Destination Province",
        destination_country: "Destination Country",
      },
      section_download: "Download Linehauls",
      "button_download-all-columns": "All Columns",
      "button_download-current-columns": "Only Visible Columns",
      "button_download-especific-columns": "Choose Especific Columns",
      "section_download-sub": "Filtering to activate",
      "button_download-max-limit": "Max. {count}",
      "button_download-incorrect-limit": "Used incorrect limit",
      "button_download-orders": "Download Linehaul Orders",
    },
    add_new_order: {
      title: "Add orders manually to this project",
      field_search_placeholder:
        "Search orders by Ref. Id or by Client Order Id",
      cancel_btn: "Cancel",
      add_btn: "Add",
      packages: "PKGS",
      undefined_time: "Undefined time",
    },
    add_new_linehaul: {
      field_platform_label: "Select suppliers",
      field_platform_placeholder: "Select suppliers",
      field_transport_label: "Select suppliers",
      field_types_label: "Select type",
      field_transport_placeholder: "Select suppliers",
      field_type_placeholder: "Select type",
    },
    create: {
      header: {
        title: "Create a Linehaul project",
        button: "Create Linehaul",
      },
      form: {
        title_dates: "Linehaul Dates",
        title_transport: " Transport",
        title_linehaul_types: " Linehaul Types",
        title_platform_destination: "Destination Platform",
        title_platform_origin: "Origin Platform",
        title_cost: "Linehaul Cost",
        label_date_end: "Linehaul Ends",
        label_date_start: "Linehaul Starts",
        label_cost: "Cost in Euros",
        label_pallets: "Nº Palets",
        placeholder_date_end: "Linehaul Ends",
        placeholder_date_start: "Linehaul Starts",
        "error-same_day-after_end":
          "Linehaul Ends must be after Linehaul Starts",
        "error-same_day-after_start":
          "Date must be in the same day and before Delivery Ends",
        "field_platform_error-must-contains": "Value not found",
        "field_transport_error-must-contains": "Value not found",
        "field_platform_error-required": "Required field",
        "field_transport_error-required": "Required field",
        "field_type_error-required": "Required field",
      },
    },
    detail: {
      header: {
        link_comment: "Add Comment",
        link_orders: "View All Orders",
        draft: "Draft",
        readyToSchedule: "Ready to Schedule",
        scheduled: "Scheduled",
        intransit: "inTransit",
        delivered: "Delivered",
      },
      form: {
        title_dates: "Linehaul Dates",
        title_transport: "Transport",
        title_linehaul_types: " Linehaul Types",
        title_platform_destination: "Destination Platform",
        title_platform_origin: "Origin Platform",
        title_cost: "Linehaul Cost",
        title_reference: "Linehaul Reference",
        title_observations: "Observations",
        label_date_end: "Linehaul Ends",
        label_date_start: "Linehaul Starts",
        label_cost: "Cost",
        label_pallets: "Nº Palets",
        field_pallets_placeholder: "Insert palets",
        placeholder_date_end: "Linehaul Ends",
        placeholder_date_start: "Linehaul Starts",
        placeholder_observations: "Add a new observation",
        field_status_tooltip_title:
          "Select a date to specify when the status changed",
        field_status_tooltip_content:
          " You can set it up today or to choose a previous day from the calendar",
        "error-same_day-after_end":
          "Linehaul Ends must be after Linehaul Starts",
        "error-same_day-after_start":
          "Date must be in the same day and before Delivery Ends",
        "field_text_observations_error-length":
          "Max length field equal to 2045",
        "field_date_error-date": "Date is not valid",
        "field_cost_error-max-value": "Value must be less equal than 1000",
        "field_pallets_error-max-value": "Value must be less equal than 1000",
        "field_pallets_error-numeric": "Palets count is not valid",
        "field_status_tooltip_btn-cancel": "Cancel",
        "field_status_tooltip_btn-today": "Set Today",
        "field_status_tooltip_btn-date": "Choose a date",
        field_status_error: "Select a valid time",
      },
      orders: {
        title: "Orders added to this project",
        link_add_order: "Add Order",
      },
      created_by: {
        label: "Linehaul Created By",
      },
      comments: {
        title: "Comments",
        link_publish: "Publish",
        label: "Add a comment",
        placeholder: "Write your comment",
        wrote: "wrote",
        field_message: "Add a comment",
        field_message_placeholder: "Write your comment",
        "not-content": "There are not comments yet",
        button_post: "Publish",
      },
      attachments: "Attachments",
      progress: "In Progress...",
      "changing-status": "Changing Status...",
    },
    "saved-changes": "Saved!",
    "saving-changes": "Saving Changes...",
    "unsaved-changes": "Changes must be yet saved!",
    "fields-on-error": "Some fields are incorrect!",
  },
  errors: {
    error_delete: "Sorry, it couldn’t be deleted. Try again later.",
    message_default:
      "There was a problem. Please, try again and if the problem persists contact us at tech{'@'}letsgoi.com",
  },
  common: {
    ...commonComponentsTranslations,
  },
  orders: {
    ...commonOrders,
    card: {
      ...cardOrder,
    },
    exports: {
      ...exportsMenuOrder,
    },
  },
  incidences: {
    ...incidencesTranslations,
  },
  linehauls: {
    ...commonLinehauls,
    card: {
      ...cardLinehaul,
    },
  },
  dedicatedServices: {
    ...commonDedicatedServices,
    card: {
      ...cardDedicatedServices,
    },
  },
  alerts,
  clients: {
    ...commonClients,
    card: {
      ...cardClient,
    },
  },
  operations: {
    ...commonOperations,
    card: {
      ...cardOperation,
    },
  },
  providers: {
    ...commonProviders,
    card: {
      ...cardProvider,
    },
  },
  teams: {
    ...commonTeams,
    card: {
      ...cardTeam,
    },
  },
};
