export default {
  title: "Overview",
  team: "Team",
  contact_number: "Contact Number",
  provider: "Provider",
  skills: "Skills",
  unspecified: "--",
  edit_overview: "Edit Overview",
  placeholder_input: "type something",
  label_team: "Team",
  label_number: "Contact Number",
  label_provider: "Provider",
  label_skills: "Skills",
  edit: "Edit",
};
