export { default as common } from "./common";
import dealInfo from "./cardDealInfo";
import proposals from "./cardProposals";
import rates from "./cardRates";
import operations from "./cardOperations";

export const card = {
  dealInfo,
  proposals,
  rates,
  operations,
};
