export { default as common } from "./common";
import additionalInfo from "./cardAdditionalInfo";
import vehicleInfo from "./cardVehicleInfo";
import overview from "./cardOverview";

export const card = {
  additionalInfo,
  vehicleInfo,
  overview,
};
