export default {
  title: "Vehicle Info",
  payload: "Payload:",
  cargo_capacity: "Cargo Capacity:",
  plate: "Plate:",
  unspecified: "--",
  edit_info: "Edit Vehicle Info",
  placeholder_input: "type something",
  label_payload: "Payload",
  label_cargo_capacity: "Cargo Capacity",
  label_plate: "Plate",
  edit: "Edit",
};
