export default {
  title: "Orders",
  ref: "Ref. ID",
  client: "Client",
  operation: "Operation",
  status: "Status",
  add_order: "Add order",
  view_all: "View all",
  pkgs: "Pkg. | Pkgs.",
  label_search: " Search orders to add",
  placeholder_search: "by Ref ID or Client Order ID",
  title_placeholder_empty: "No {content} added",
  subtitle_placeholder_empty: "Get started by adding content",
  label_ref: "Ref. ID:",
  label_client: "Client:",
  label_operation: "Operation:",
  label_status: "Status:",
};
