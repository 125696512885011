export default {
  list: {
    title: "Providers",
    label_input_address: "Address",
    total_count: "Showing {totalcount} Results",
    button_add_provider: "Add Provider",
  },
  detail: {
    title_section_profile: "Provider Profile",
    title_section_base_location: "Base Location",
    label_input_name: "Name",
    label_input_address: "Address",
    label_input_additional_address: "Additional Address",
    label_input_city: "City",
    label_input_zip: "Zip",
    label_input_province: "Province",
    label_input_country: "Country",
    placeholder_input_name: "Insert Name",
    placeholder_input_address: "Insert Customer Address, nº...",
    placeholder_input_additional_address: "4th floor",
    placeholder_input_city: "Madrid",
    placeholder_input_zip: "28012",
    placeholder_input_province: "Insert Province",
    placeholder_input_country: "Select Country",
    updating_provider: "Updating provider",
    btn_cancel: "Cancel",
    btn_save: "Save",
  },
  creation: {
    title_section_profile: "Provider Profile",
    title_section_base_location: "Base Location",
    label_input_name: "Name",
    label_input_address: "Address",
    label_input_additional_address: "Additional Address",
    label_input_city: "City",
    label_input_zip: "Zip",
    label_input_province: "Province",
    label_input_country: "Country",
    placeholder_input_name: "Insert Name",
    placeholder_input_address: "Insert Customer Address, nº...",
    placeholder_input_additional_address: "4th floor",
    placeholder_input_city: "Madrid",
    placeholder_input_zip: "28012",
    placeholder_input_province: "Insert Province",
    placeholder_input_country: "Select Country",
    creation_provider: "Creating provider",
    btn_cancel: "Cancel",
    btn_save: "Save",
  },
};
