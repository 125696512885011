declare global {
  namespace Orders {
    interface Section {
      id: string;
      index: number;
      title: string;
      icon: string;
    }
    type OrderFilterSectionId =
      | "GRP"
      | "MANAGEMENT"
      | "CUSTOMER"
      | "LOGISTICS"
      | "LOCATION"
      | "DATES"
      | "EXTRA";

    interface Namespaces {
      list: string;
      url: string;
      export: string;
      routes: string;
      stops: string;
      path: string;
    }

    interface WizardRouteExportOptions {
      all: string;
      selected: string;
    }

    interface HourData {
      name: string;
      value: string;
      hour: string;
      minutes: string;
    }
    interface Article {
      id: string;
      external_id: string;
      metadata: string;
      name: string;
      volume: number;
      weight: number;
      tags: Tag[];
      width: number;
      length: number;
      height: number;
    }

    type ArticlePayloadPost = OverwriteOptionalProperties<
      Article,
      {
        id?: string;
        metadata?: string;
        width?: number;
        length?: number;
        height?: number;
        tags?: string[];
      }
    >;

    type ArticlePayloadPatch = OverwriteOptionalProperties<
      Article,
      {
        tags?: string[];
      }
    >;

    interface OrderArticle {
      id: string;
      article: Article;
      barcodes: Barcode[];
      packages?: Package[];
      tags: Tag[];
    }

    interface OrderArticleUI {
      id: string;
      article: Article;
      barcodes: Barcode[];
      packages?: PackageUI[];
      tags: Tag[];
    }

    interface OrderArticlePayload {
      id?: string;
      barcodes?: Barcode[];
      packages?: Package[];
      tags?: string[];
    }

    interface ClientOrderId {
      id: string;
      external_id: string;
      tags: Tag[];
    }

    interface PlatForm {
      id: string;
      name: string;
      address: Address;
      provider_types: Providers.ProviderType[];
    }

    interface DeliveryZone {
      id: string;
      name: string;
      default_platform: PlatForm;
    }

    interface DeliveryAddress extends Address {
      delivery_zone: DeliveryZone;
    }

    interface OrderGroup {
      id: string;
    }

    interface PickupZone {
      id: string;
      name: string;
    }

    interface PickupAddress extends Address {
      pick_up_zone: PickupZone;
    }

    interface Pod {
      id: string;
      name: string;
      tags: Tag[];
      created_at: string;
    }

    type PodUI = OverwriteProperties<
      Pod,
      {
        created_at: Date;
      }
    >;

    interface Service {
      id: string;
      name: string;
      code: string;
    }

    interface Status {
      id: string;
      name: string;
      color: string;
      unique: boolean;
      final: boolean;
    }

    interface StatusPayload {
      status: string;
      tracked_at?: string;
    }

    interface OrderPayload {
      operation_id: string;
      address: AddressPayload;
      articles?: OrderArticle[] | Partial<OrderArticle>[];
      client_order_ids?: {
        external_id: string;
        tags: string[];
      }[];
      customer_firstname: string;
      customer_lastname?: string;
      customer_phone: string;
      customer_email?: string;
      delivery_time_from?: string;
      delivery_time_to?: string;
      delivery_estimated_duration?: number;
      packages?: PackagePayload[];
      provider_id?: string;
      weight?: number;
      volume?: number;
      cash_on_delivery?: number;
      reverse_logistics?: boolean;
      related_order_id?: string;
      retail_price?: number | null;
      services?: string[];
      team_id?: string;
      team_notes?: string;
      metadata?: string;
      tags?: string[];
      client_order_id?: string;
      external_tracking_id?: string;
      rating?: number;
      order_reschedule_reason_id?: string;
    }

    interface Package {
      id: string;
      articles: OrderArticle[];
      reference: string;
      order: Order;
      barcodes: Barcode[];
      weight: number;
      volume: number;
      width: number;
      height: number;
      length: number;
      picked_up_at: string;
      metadata: string;
      created_at: string;
      pallet?: {
        reference: string;
        linehaul_id: string;
      };
      last_scan?: Scan;
    }

    interface Scan {
      platform: PlatForm;
      zone: IdName;
      tracked_at: string;
      latitude: number;
      longitude: number;
      scanned_by: string;
    }

    type ScanUI = OverwriteProperties<
      Scan,
      {
        tracked_at: Date;
      }
    >;

    type PackageUI = OverwriteProperties<
      Package,
      {
        picked_up_at: Date;
        created_at: Date;
        last_scan?: ScanUI;
      }
    >;

    interface PackagePayload {
      barcodes?: Barcode[];
      client_package_id?: string;
      reference?: string;
      weight?: number;
      volume?: number;
      width?: number;
      height?: number;
      length?: number;
      picked_up_at?: string;
      metadata?: string;
    }

    interface Order {
      address: Address;
      articles: OrderArticle[];
      barcode: string;
      barcode_encoding: string;
      barcodes: Barcode[];
      cash_on_delivery: number;
      client_order_id: string;
      client_order_ids: ClientOrderId[];
      created_at: string;
      customer_email: string;
      customer_firstname: string;
      customer_lastname: string;
      customer_phone: string;
      delivery_address: DeliveryAddress;
      delivery_estimated_duration: number;
      delivery_instructions: string;
      delivery_time_from: string;
      delivery_time_to: string;
      distance_from_delivery_zone: number;
      distance_from_pick_up: number;
      distance_from_province: number;
      external_tracking_id: string;
      first_attempt_at: string;
      flow_code: string;
      has_attachments: boolean;
      id: string;
      incidences_count: number;
      last_incidence: Incidence;
      last_open_incidence: Incidence;
      metadata: string;
      operation: Operations.Operation;
      order_group: OrderGroup;
      packages: number;
      packages_count: number;
      pick_up_address: PickupAddress;
      picked_up_at: string;
      platform: PlatForm;
      pod: Pod | null;
      provider: Providers.Provider;
      rating: number;
      reference: string;
      related_order_id?: string;
      retail_price: number;
      reverse_logistics: boolean;
      services: Service[];
      shipped_at: string;
      closed_at: string;
      status: Status;
      tags: Tag[];
      team: Omit<Teams.Team, "route_for_date" | "routes_for_date">;
      team_notes: string;
      updated_at: string;
      volume: number;
      weight: number;
      all_packages_in_same_zone: boolean | null;
    }

    type OrderUI = OverwriteProperties<
      Order,
      {
        delivery_time_from: Date;
        delivery_time_to: Date;
        shipped_at: Date;
        closed_at: Date;
        updated_at: Date;
        created_at: Date;
        picked_up_at: Date;
        pod: PodUI | null;
      }
    >;

    interface OrderListState {
      data: Order[];
      meta: Meta;
    }

    interface EventLog {
      description: string;
      type: string;
      created_by: string;
      metadata: string;
      created_at: string;
    }

    type EventLogUI = OverwriteProperties<
      EventLog,
      {
        created_at: Date;
      }
    >;

    interface EventLogPayload {
      description: string;
      type: string;
      metadata: string;
    }

    interface OrderLog {
      created_at: string;
      new_values: Record<string, unknown>;
      old_values: Record<string, unknown>;
      source: string;
    }

    type OrderLogUI = OverwriteProperties<
      OrderLog,
      {
        created_at: Date;
      }
    >;

    interface StatusHistory {
      id: string;
      status: Status;
      tracked_at: string;
      created_at: string;
      latitude?: number;
      longitude?: number;
      source: string;
      zone?: IdName;
    }

    type StatusHistoryUI = OverwriteProperties<
      StatusHistory,
      {
        tracked_at: Date;
        created_at: Date;
      }
    >;

    interface Attachement {
      id: string;
      url?: string;
      created_at: string;
      name: string;
      tags: Tag[];
    }

    interface DetailStore {
      order: Order;
      groupOrders: Order[];
      articles: OrderArticle[];
      packages: Package[];
      eventLogs: EventLog[];
      logs: OrderLog[];
      statusHistory: StatusHistory[];
      comments: Comment[];
      attachments: Attachement[];
      incidences: Incidence[];
      invoices: Invoicing.InvoiceItem[];
    }

    interface StorageLocations {
      id: string;
      picked_up_at: string;
      storage_location: IdName;
      created_at: string;
    }

    interface StorageLocationPayload {
      storage_location_name: string;
    }

    interface AvailableDays {
      day: string;
      slot: {
        name: string;
        start: string;
        end: string;
      };
      max_capacity: number;
      occupancy: number;
      created_at: string;
    }

    interface RescheduleReasons {
      id: string;
      description: string;
    }

    type OrderColWithGrpNames = NestedKeys<Orders.Order> | GroupColumns;
    interface Column<SECTION> {
      column: OrderColWithGrpNames;
      export?: NestedKeys<Orders.Order>;
      childColumns?: NestedKeys<Orders.Order>[];
      name: string;
      section: SECTION;
      tags?: ColumnTag[];
    }

    type GroupColumns =
      | "grpOrder"
      | "grpClient"
      | "grpPickup"
      | "grpDelivery"
      | "grpDeliveryTime"
      | "grpContent"
      | "grpExtras";
    type ColumnTag = "extras";

    interface Flow {
      id: string;
      code: string;
      name?: string;
    }
  }
}

export enum FormCreationTemplate {
  LEROYMERLIN = "leroymerlin",
  MEDIAMARKT = "mediamarkt",
  STANDARD = "standard",
}

export {};
